import React from 'react'
import * as Sentry from '@sentry/react'
import Amplify, { Auth } from 'aws-amplify'
import awsmobile from './aws-exports'
import {
  AuthState,
  onAuthUIStateChange,
  CognitoUserInterface,
} from '@aws-amplify/ui-components'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Page404 from './pages/Page404'
import Saml from '@features/auth/slots/Saml'
import FbAppStore from './pages/AppStore/FbAppStore'
import Fbe from './pages/Fbe'
import FbeCallback from './pages/FbeCallback'
import LegacyRedirect from './pages/LegacyRedirect'
import Logout from './pages/Logout'
import Close from './pages/AppStore/Close'
import FbAppStoreTest from './pages/AppStore/FbAppStoreTest'
import { SignUp } from './pages/Signup'
import {
  setAuthStateAction,
  setCurrentUserAction,
  unsetAuthStateAction,
  unsetCurrentUserAction,
} from './store/actions/UserActions'
import SamlRedirect from './pages/SamlRedirect'
import { AuthRouter } from '@features/auth/AuthRouter'
import { appRoutes, bridgeByUrlPartnersPaths } from './routes'
import { useFirstLandRedirect } from '@features/auth/functions/useFirstLandRedirect'
import { parseAuthUser } from '@app/features/auth/functions/parseAuthUser'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { TestComponents } from '@app/pages/TestComponents'
import { GlobalAppStyle } from '@app/styles/GlobalAppStyle'
import { GlobalLoginStyle } from '@app/features/auth/components/AuthContainer.styled'
import { BridgeByUrlCallback } from '@app/pages/BridgeByUrlCallback'
import { PricingPage } from '@app/pages/Pricing'
import { CheckoutPage } from '@app/pages/Checkout/index'
import { SignUpBbu } from '@app/pages/SignupBbu'
import { NbeeRouter } from '@app/pages/Nbee'
import { DestinationContentSample } from '@components/ConnectedPopupWindow/DestinationContentSample'
import { IntegrationRouter } from '@app/pages/Integration'
import { ThankYouPage } from '@app/pages/ThankYouPage'
import { SuperUserInfoBar } from '@features/auth/SuperUserInfoBar'
import {
  identifyUserByUserData,
  trackEvent,
  useTrackPageView,
} from '@app/dataTracking'
import { SignUpAppSumo } from '@app/pages/SignupAppSumo'
import { SignupBbuFallbackSurvey } from '@app/pages/SignupBbuFallbackSurvey'
import { IncomingDataRouter } from '@app/pages/IncomingData'
import { GoogleAdsAccountRouter } from '@app/pages/GoogleAdsAccount'
import { AdminPages } from '@app/pages/Admin'
import { IntegrationAuthVerifiedEmailScreenLegacy } from '@app/pages/Integration/IntegrationAuthVerifiedEmailScreenLegacy'
import { Loader } from '@components/Basic/Loader'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { useGetUserModules } from '@app/api/getUserModules'
import { setTrackSignIn } from '@app/store/actions/ApplicationConfigurationActions'
import {
  HandleSignupCognitoProps,
  signInUserSessionCognito,
} from '@app/services/apiAuthClient/utils'

export const helpCenterUrl =
  'https://community.activeprospect.com/search?keywords=leadsbridge'

Amplify.configure(awsmobile)

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const authState = useAppSelector((state) => state.user.authState)
  const userAuthState = useAppSelector((state) => state.user.auth)
  const trackSignIn = useAppSelector((state) => state.application.trackSignIn)
  const history = useHistory()

  const { zE } = window

  const [shouldFetchUserData, setShouldFetchUserData] = React.useState(false)

  const { data: userData, isLoading: isLoadingUser } =
    useGetUserInfo(shouldFetchUserData)
  const { data: userModulesResponse } = useGetUserModules(shouldFetchUserData)

  const userModules = userModulesResponse?.modules || []
  const supportUserModule = userModules.find((m) => m.id === 'customer-support')
  const downgradeStatus = userData?.user.downgradeStatus

  const { initialSigninUrl, redirectsToWhenLogged } = useFirstLandRedirect()

  const handleAuthStateUpdate = async () => {
    await Auth.currentAuthenticatedUser()
      .then((authData) => {
        setShouldFetchUserData(true)
        const cognitoUser = parseAuthUser(authData as CognitoUserInterface)
        dispatch(setAuthStateAction(AuthState.SignedIn))
        dispatch(setCurrentUserAction(cognitoUser))
      })
      .catch((e) => {
        console.info('no auth state:', e)
        dispatch(unsetAuthStateAction())
        dispatch(unsetCurrentUserAction())
      })
    return onAuthUIStateChange(
      (nextAuthState: AuthState, authData?: Record<string, any>) => {
        const cognitoUser = parseAuthUser(authData as CognitoUserInterface)
        dispatch(setAuthStateAction(nextAuthState))
        dispatch(setCurrentUserAction(cognitoUser))
      }
    )
  }

  // Store the data from postMessage in a state
  const [authDataFromPostMessage, setAuthDataFromPostMessage] =
    React.useState<HandleSignupCognitoProps | null>(null)

  const isPopupWindow = !!window.opener

  // We are expecting to receive data as postMessage from parent window
  const messageReceiverFn = function (event: MessageEvent) {
    const allowedOrigins = (
      process.env.REACT_APP_ALLOWED_ORIGINS || 'https://app.leadsbridge.com'
    )
      .split(',')
      .map((origin) => origin.trim())
      .filter(Boolean)

    if (!allowedOrigins.includes(event.origin)) {
      console.warn(`Received message from unauthorized origin: ${event.origin}`)
      return
    }

    if (event.data?.isLbMessage) {
      console.info(
        'Received post message from iframe with origin: ',
        event.origin
      )

      const username = event.data.username
      const idToken = event.data.idToken
      const accessToken = event.data.accessToken
      const refreshToken = event.data.refreshToken
      const isIframe = event.data.isIframe

      if (
        !username ||
        !idToken ||
        !accessToken ||
        !refreshToken ||
        typeof username !== 'string' ||
        typeof idToken !== 'string' ||
        typeof accessToken !== 'string' ||
        typeof refreshToken !== 'string'
      ) {
        console.error('Received invalid credentials')
        return
      }
      setAuthDataFromPostMessage({
        username: username,
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
        isIframe: isIframe,
      })
    }
  }

  // Listen for message returned from popup
  React.useEffect(() => {
    if (isPopupWindow) {
      window.addEventListener('message', messageReceiverFn)
      return () => window.removeEventListener('message', messageReceiverFn)
    }
  }, [isPopupWindow])

  // Handle Signup with data from postMessage when the user is not logged AND the parent window is in an iframe
  React.useEffect(() => {
    if (
      authDataFromPostMessage &&
      authState !== AuthState.SignedIn &&
      authDataFromPostMessage.isIframe
    ) {
      signInUserSessionCognito(authDataFromPostMessage)
      handleAuthStateUpdate()
    }
  }, [authDataFromPostMessage])

  // send page view to segment
  useTrackPageView(!!userAuthState?.username)

  React.useEffect(() => {
    if (userData && downgradeStatus !== 'no') {
      history.push(`/pricing?reason=${downgradeStatus}`)
    }
  }, [userData])

  React.useEffect(() => {
    if (zE && supportUserModule) {
      zE('webWidget', 'identify', {
        name: supportUserModule?.userDefaults.fullName,
        email: supportUserModule?.userDefaults.email,
        organization: supportUserModule?.userDefaults.fullName,
      })

      zE('webWidget', 'updateSettings', {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: function (callback: any) {
                const jwt: string = supportUserModule?.userDefaults.jwt || ''
                if (
                  jwt &&
                  jwt !== '' &&
                  supportUserModule?.userDefaults?.chatAvailable
                ) {
                  callback(jwt)
                }
              },
            },
          },
          chat: {
            hideWhenOffline: false,
            suppress: !supportUserModule?.userDefaults?.chatAvailable,
          },
          contactForm: {
            attachments: true,
            ticketForms: [{ id: supportUserModule?.userDefaults.formId }],
          },
        },
      })
      zE('webWidget', 'close')
      zE('webWidget', 'hide')
      if (supportUserModule) {
        zE('webWidget', 'prefill', {
          name: {
            value: supportUserModule?.userDefaults.fullName,
          },
          email: {
            value: supportUserModule?.userDefaults.email,
            readOnly: true,
          },
        })
      }
    }
  }, [supportUserModule])

  /**
   * Set user State in Redux State (SET_CURRENT_USER)
   */
  React.useEffect(() => {
    // Aws authenticator doesn't work as expected with React-Router,
    // see: https://github.com/aws-amplify/docs/issues/2895

    if (authState === undefined) {
      handleAuthStateUpdate()
    }
  }, [authState])

  /**
   * Handle Tracking script (Logged User)
   */
  React.useEffect(() => {
    if (userData) {
      identifyUserByUserData(userData).then((r) =>
        console.info('App: Identify User: By UserData', r)
      )
      if (trackSignIn) {
        trackEvent({
          eventName: 'SignIn',
          feature: 'Authentication',
          step: 'Signin',
        })
        dispatch(setTrackSignIn(false))
      }
      setShouldFetchUserData(false)
    }
  }, [userData])

  /**
   * Register user data to Sentry
   * https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
   */
  React.useEffect(() => {
    if (userAuthState && userAuthState.attributes) {
      Sentry.setUser({
        email: userAuthState.attributes.email,
        id: userAuthState.attributes['custom:legacyUserId'],
      })
    } else {
      Sentry.getCurrentScope().setUser(null)
    }
  }, [userAuthState?.username])
  return (
    <div>
      <GlobalAppStyle />
      {(userAuthState === undefined && authState === undefined) ||
      (isLoadingUser && !userData) ||
      (window.opener && authState !== AuthState.SignedIn) ? (
        <>
          {/* For a brief amount of seconds, in the beginning, both authState and userAuthState are undefined. */}
          {/* In these cases, we want to display a Loader. */}
          <Loader $active $dimmer $size={'large'} />
        </>
      ) : authState === AuthState.SignedIn && userAuthState ? (
        <>
          {/* LOGGED USER */}
          {/* If the user has logged in successfully, its authState will be equal to 'signin', */}
          {/* And its userAuthState will contain the user object obtained from Cognito. */}
          <SuperUserInfoBar />
          <Switch>
            <Route
              path={appRoutes.changePassword.makUrl()}
              component={AuthRouter}
            />
            <Route
              path={[
                appRoutes.googleAdsAccountFormCreate.path,
                appRoutes.googleAdsAccountFormCreated.path,
                appRoutes.googleAdsAccountActivate.path,
                appRoutes.googleAdsAccountConnect.path,
              ]}
              component={GoogleAdsAccountRouter}
            />
            <Route
              path={[
                appRoutes.nbeeAppBbu.path,
                appRoutes.nbee.path,
                appRoutes.signupBbu.path,
                ...bridgeByUrlPartnersPaths,
              ]}
              component={NbeeRouter}
            />

            <Route
              path={appRoutes.pricing.path}
              exact
              component={PricingPage}
            />
            <Route
              path={appRoutes.checkout.path}
              exact
              component={CheckoutPage}
            />
            <Route
              path={appRoutes.checkoutThankYouPage.path}
              exact
              component={ThankYouPage}
            />
            <Route
              path={appRoutes.fbAppStoreDone.path}
              exact
              component={Close}
            />
            <Route path={appRoutes.fbAppStore.path} component={FbAppStore} />
            <Route path='/fbAppStoreTest' component={FbAppStoreTest} />

            <Route
              path={appRoutes.fbeCallback.path}
              exact
              component={FbeCallback}
            />
            <Route path={appRoutes.fbe.path} exact component={Fbe} />

            <Route
              path={appRoutes.incomingData.path}
              component={IncomingDataRouter}
            />

            <Route
              path={appRoutes.integration.path}
              component={IntegrationRouter}
            />

            <Route path={appRoutes.bridgeByUrlCallback.path}>
              {/* here we handle the fast app survey, if needed */}
              <BridgeByUrlCallback isMountedInLoggedRoute={true} />
            </Route>

            <Route
              path={appRoutes.adminPrivateRoot.path}
              component={AdminPages}
            />

            <Route path={appRoutes.logout.path} component={Logout} />
            {/* users will always land on the app page as not logged,
                        so we need to "reverse" the redirect on the not logged <Switch>
                        to do this, we add a `?redirectTo=..` query string as url param
                        (logic inside useFirstLandRedirect hook)
                    */}
            <Route path={appRoutes.signin.path}>
              <Redirect to={redirectsToWhenLogged} />
            </Route>
            <Route path='/test-app-route' exact>
              Welcome to Leadsbridge
            </Route>
            <Route path='/test-components' exact component={TestComponents} />
            {/* Route for Legacy Email Verification */}
            <Route
              path={'/verify-email'}
              exact
              component={IntegrationAuthVerifiedEmailScreenLegacy}
            />
            <Route path='/*' exact>
              <LegacyRedirect />
            </Route>
            <Route component={Page404} />
          </Switch>
        </>
      ) : (
        <>
          {/* NOT LOGGED USER */}
          {/* When the user is not logged, userAuthState will be undefined, while authState will still hold a value */}
          <GlobalLoginStyle />
          <Switch>
            <Route path='/test-components' exact component={TestComponents} />

            <Route
              path='/connected-popup-destination-url-example'
              exact
              component={DestinationContentSample}
            />
            <Route path={appRoutes.saml.path} exact component={Saml} />
            <Route
              path={appRoutes.samlRedirect.path}
              component={SamlRedirect}
            />
            <Route path={appRoutes.logout.path} component={Logout} />

            {/* Signup */}
            <Route path={appRoutes.signupBbu.path} component={SignUpBbu} />
            <Route
              path={appRoutes.signupPartner.path}
              component={SignUpAppSumo}
            />
            <Route path={appRoutes.signup.path} component={SignUp} />

            {/* Signup BBU Fallback survey is called when partner bbu fails to retrieve user email address from partner oauth. We signup the user asking his email address as survey. */}
            <Route
              path={appRoutes.signupBbuFallbackSurvey.path}
              component={SignupBbuFallbackSurvey}
            />

            {/* AmplifyAuthenticator in AuthRouter component */}
            {/* exact matching paths here: */}
            <Route
              path={[
                appRoutes.fbe.path,
                appRoutes.fbeCallback.path,
                appRoutes.fbAppStoreDone.path,
              ]}
              exact
              component={AuthRouter}
            />
            {/* partial matching paths here: */}
            <Route
              path={[
                appRoutes.signin.path,
                appRoutes.nbeeAppBbu.path,
                ...bridgeByUrlPartnersPaths,
                appRoutes.fbAppStore.path,
              ]}
              component={AuthRouter}
            />

            <Route path={appRoutes.bridgeByUrlCallback.path}>
              <BridgeByUrlCallback isMountedInLoggedRoute={false} />
            </Route>

            {/* Route for Legacy Email Verification */}
            <Route
              path={'/verify-email'}
              exact
              component={IntegrationAuthVerifiedEmailScreenLegacy}
            />

            {/* old saml url -> redirect to new one  */}
            <Route path='/saml' exact>
              <Redirect to={appRoutes.saml.makeUrl()} />
            </Route>

            {/* When not logged, every other path will redirect to signin */}
            <Route path='/*' exact>
              <Redirect to={initialSigninUrl} />
            </Route>
          </Switch>
        </>
      )}
    </div>
  )
}

export default App
